export interface GlobalStateType {
  deviceSize: {
    width?: number | null;
    height?: number | null;
  };
}

// DEFAULT VALUE GLOBAL STATE
export const globalState: GlobalStateType = {
  deviceSize: {
    width: null,
    height: null,
  },
};
