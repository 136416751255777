import React, { createContext, Dispatch, useContext, useReducer } from "react";
import { globalState, GlobalStateType } from "./state";
import reducer from "./reducer";

export interface InitialContextType {
  state: GlobalStateType;
  dispatch: Dispatch<Partial<GlobalStateType>>;
}

export const initialContext: InitialContextType = {
  state: globalState,
  dispatch: () => null,
};

export const StoreContextWrapper = createContext(initialContext);

export const context = () => useContext(StoreContextWrapper);

const StoreContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, globalState);
  return (
    <StoreContextWrapper.Provider value={{ state, dispatch }}>
      {children}
    </StoreContextWrapper.Provider>
  );
};

export default StoreContextProvider;
